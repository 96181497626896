/* global window */
'use strict';

import './../../../helpers/error-catch';
import React from 'react';
import ReactDOM from 'react-dom';
import PurchaseButton from './purchase-button';
import CartContents from './cart-contents';
import CartIcon from './cart-icon';
import Actions from './actions';

const carts = window.document.getElementsByClassName('shopping-cart');

Array.prototype.forEach.call(carts, ( cart) => {
    ReactDOM.render(<CartIcon />, cart);
});

const buttons = window.document.getElementsByClassName('purchase-button');

Array.prototype.forEach.call(buttons, ( button) => {
    ReactDOM.render(<PurchaseButton price={button.dataset.price} name={button.dataset.name} compact={button.dataset.compact}  type={ button.dataset.type } id={ button.dataset.id } />, button);
});

const cartContents = window.document.getElementsByClassName('cart-contents');

Array.prototype.forEach.call(cartContents, ( cartContent ) => {
    ReactDOM.render(<CartContents />, cartContent);
    Actions.getProductResults();
});

Actions.getResults();


