/* global window */
'use strict';

import React from 'react';
import Actions from './actions';
import Store from './store';

class CartContents extends React.Component {

    constructor(props) {

        super(props);

        this.state = Store.getState();
    }

    componentDidMount() {

        Actions.getProductResults(Store, true);
        this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
    }

    componentWillUnmount() {

        this.unsubscribeStore();
    }

    onStoreChange() {

        this.setState(Store.getState());
    }

    remove(id, type) {
        Actions.deleteItem(Store, id, type, ( err ) => {
            if ( !err ){
                Actions.getProductResults(Store);
            }
        });
    }

    checkout(event) {

        Actions.startCheckout(Store);
    }

    render() {

        const purchasables = this.state.cartContents.items.map((item) => {

            return (
                <div key={item.type + '-' + item.id} className="row cart-contents">
                    {/*
                    <div className="col-sm-8 col-xs-8">
                        <h4><a href={`/buynow/${id}`} >{product.name}</a></h4>
                    </div>
                    <div className="col-sm-2 col-xs-4 text-right"><h4>{product.price}</h4></div>
                    <div className="col-sm-2 col-xs-12"><button onClick={this.remove.bind(this, id)} className="btn btn-default btn-block">Remove</button></div>
                    */}
                    <div className="col-sm-9 col-xs-7">
                        <h4><a href={`/buynow/${item.id}`} >{item.name}</a></h4>
                    </div>
                    <div className="col-sm-2 col-xs-3 text-right"><h4>{item.price}</h4></div>
                    <div className="col-sm-1 col-xs-1 cart-trash-div" onClick={this.remove.bind(this, item.id, item.type)} ><i className="fa fa-trash cart-trash"></i></div>
                </div>
            );
        });

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h3 className="panel-title">Items</h3>
                </div>
                <div className="panel-body">
                    { purchasables }
                    <hr className="cart" />
                    <div className="row cart-contents">
                        <div className="col-sm-9 col-xs-7">
                            <h4>Total</h4>
                        </div>
                        <div className="col-sm-2 col-xs-3 text-right"><h4>${this.state.cartContents.total}</h4></div>
                    </div>
                    <div className="row cart-contents">
                        <div className="col-xs-12"><a href='/buynow/checkout' className="btn btn-success btn-block btn-lg">Checkout</a></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CartContents;

