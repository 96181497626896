/* global window */
'use strict';

import './../../../helpers/error-catch';
import React from 'react';
import PropTypes from 'prop-types';
import Store from './store';
import Actions from './actions';

const propTypes = {
};

class CartIcon extends React.Component {

    constructor(props) {

        super(props);

        this.state = Store.getState();
    }

    componentDidMount() {

        Actions.getResults(Store, true);
        this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
    }

    componentWillUnmount() {

        this.unsubscribeStore();
    }

    onStoreChange() {

        this.setState(Store.getState());
    }

    render() {

        const count = this.state.cart.items.length;
        let sup = '';
        if ( count > 0 ){
            sup = (<sup>{count}</sup>);
            return ( <a href='/buynow/cart' ><i className="fa fa-shopping-cart"></i>{sup}</a> );
        }

        return '';
    }
}

CartIcon.propTypes = propTypes;

export default CartIcon;

