/* global location gtag */
'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Actions from './actions';
import Store from './store';

const propTypes = {
    id: PropTypes.string,
    compact: PropTypes.string,
    type: PropTypes.string,
    price: PropTypes.string,
    name: PropTypes.string
};

class PurchaseButton extends React.Component {

    constructor(props) {

        super(props);

        this.state = Store.getState();
    }

    componentDidMount() {

        Actions.getResults(Store, true);
        this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
    }

    componentWillUnmount() {

        this.unsubscribeStore();
    }

    onStoreChange() {

        this.setState(Store.getState());
    }

    onAdd(event) {
        event.preventDefault();
        //fixed for now
        Actions.addItem(Store, this.props.id, 'my', this.props.type);
        if ( typeof gtag !== 'undefined' ) {
            gtag('event', location.href, {
                'event_category': 'Add to Cart',
                'event_label': this.props.name
            });
        }
    }
    onRemove(event) {
        event.preventDefault();
        Actions.deleteItem(Store, this.props.id, this.props.type);
        if ( typeof gtag !== 'undefined' ) {
            gtag('event', location.href, {
                'event_category': 'Remove from Cart',
                'event_label': this.props.name
            });
        }
    }

    render() {

        const key = this.props.type + '-' + this.props.id;
        let content;
        let click;
        const compact = !!this.props.compact === true;
        const purchaseBtnCompact = compact ? 'purchase-btn-compact' : '';
        const pending = !!this.state.cart.pending[key];

        const price = this.props.price || '';
        const split = price.split('.');
        let dollars = price;
        let cents = '';
        if ( split && split.length === 2){
            dollars = split[0];
            cents = '.' + split[1];
            if ( cents === '.00') {
                cents = '';
            }
        }

        const addToCart = compact ? 'Add' : 'ADD TO CART';
        const removeFromCart = compact ? 'Remove' : 'REMOVE FROM CART';
        if ( !this.state.cart.hydrated || pending){
            content = ( <i className="fa fa-spinner fa-spin"></i> );
        }
        else {
            if ( this.state.cart.items.some((item) => {
                return item.id === this.props.id && item.type === this.props.type;
            })) {
                content = removeFromCart;
                click = this.onRemove.bind(this);
            }
            else {
                if ( compact ){
                    content = (<span>${dollars}{cents}</span>);
                } else {
                    content = (<span><i className="fa fa-shopping-cart fa-1x"></i> {addToCart}</span>);
                }

                click = this.onAdd.bind(this);
            }
        }

        return ( <button onClick={click} className={`purchase-btn ${purchaseBtnCompact} btn btn-default btn-block`}>{content}</button> );
    }
}

PurchaseButton.propTypes = propTypes;

export default PurchaseButton;
