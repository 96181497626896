/* global window */
'use strict';

import Log from './log';
import StackTrace from 'stacktrace-js';
/*
 I tried the other libraries from stacktrace.js,  StackTraceGPS, ErrorStackParser, and StackFrame and the other but they didn't seem to do much and
 StackTrace seems to give me what I want.  The tricky issue is what type of source map.  When I changed things started to work.
 */

//might capture more errors with window.addEventListener('error', function(){}, true);//this catches the ones that don't bubble
//like image loading errors.  But for script errors doesn't give detail like onerror does

//don't want this to run server side
if ( global.window ) {

    //for some reason these are sent twice.  Could be related to react, not sure.  I tested the events and they
    //aren't being fired twice.
    window.onerror = (errorMsg, url, lineNumber, column, error) => {

        //Bluebird promises via global.Promise = require('bluebird'); and Promise.config({longStackTraces: true})
        //might help give better stack traces on promise chains

        //prob don't need this anymore
        //window.console && console.log && console.log('Error: ' + errorMsg + ' Script: ' + url + ' Line: ' + lineNumber
        //    + ' Column: ' + column + ' StackTrace: ' + errorObj + stack);

        const details = {
            errorMsg,
            url,
            lineNumber,
            column,
            stack: error ? error.stack : null
        };
        //turn off source map in dev tools or things get converted out from under you I think
        StackTrace.fromError(error).then((sfs) => {

            details.mapped_stack = sfs;
            Log('Error', details);

        }).catch((err) => {
            console.log('could not deal with error: ', err);
        });
        return false;
    };
}
